@import '../../styles/common';

div#confirm-identity {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .btn-footer {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button.button {
      min-width: 40%;
      margin-bottom: 20px;
    }
  }
}
