form.login-form {
  text-align: center;

  .login-footer {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .divider {
      margin-top: -2px;
      margin-left: 10px;
      margin-right: 10px;
    }

    small {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
