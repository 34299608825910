@import 'styles/common';
@import 'views/Walkthrough/style';
@import 'views/Landing/style';
@import 'views/IDCard/style';

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-weight: 200;
  letter-spacing: 0.15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-family: 'Raleway';
  background-color: #ffffff;
}

// This class is used to aply our html styles wherever they are not inherited
// specicially this happens in the HTML that we pull in from the server for the privacy policy
.html-class {
  margin: 0;
  padding: 0;
  height: 100%;
  font-weight: 200;
  letter-spacing: 0.15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-family: 'Raleway' !important;
  background-color: #ffffff;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #0059c9;
}
input {
  border: 0;
  padding: 0.75rem 0.65rem;
}
input:focus {
  outline: none;
}

main {
  width: 100%;
  height: 100%;
}

.route-wrapper {
  height: 100%;
}

.centerize {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;

  &.dashboard {
    align-items: flex-start;
  }

  @media screen and (min-width: $mobileBreakpoint) {
    display: flex;
  }
}

section {
  position: relative;
}

div.fieldset-container {
  margin: 0 0 2rem;

  .input-error {
    margin-left: 5px;
  }
}

fieldset.input-error {
  border: 3px solid rgba(255, 0, 0, 0.5);
}

fieldset {
  font-size: 90%;
  position: relative;
  padding: 0;
  background: #ffffff;
  /* padding: 0.65rem 0 0; */
  // background: linear-gradient(45deg, #282f3d 0%, #1e232e 100%);
  // box-shadow: 0 0 8px rgba(14, 16, 21, 0.35);
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 0;
  line-height: 1;
  font-family: 'Raleway';
  margin: 25px 0;
}

fieldset legend {
  color: #e6e7e8;
  font-family: 'Raleway';
  background: #007ec0; // "Ocean" from our colors js file. Need to write components for this
  margin: 0;
  padding: 4px 6px;
  font-weight: 400;
  font-size: calc(1rem * 0.8);
  text-transform: uppercase;
  line-height: 1;
  border-radius: 2px;
  position: absolute;
  bottom: 100%;
  left: 0.5rem;
  transform: translateY(25%);
}

fieldset input,
fieldset textarea {
  color: #7483a4;
  font-size: inherit;
  width: 100%;
  height: auto;
  line-height: 1;
  background: transparent;
  margin: 0;
  border: 0;
  box-shadow: none;
  padding: 0.85rem 0.65rem 0.85rem;
  font-size: 1rem;
  /* padding: 0.5rem 0.75rem 0.75rem; */
}
fieldset .fieldset-split {
  display: inline-block;
  width: calc(50% - 1px);
  border-left: 1px solid #ddd;
  padding-right: 5px;
}
fieldset .fieldset-split:first-of-type {
  display: inline-block;
  width: calc(50% - 1px);
  border-left: 1px solid transparent;
}
fieldset input::placeholder,
fieldset textarea::placeholder {
  color: #9aa6c0;
  font-family: 'Raleway';
}
p {
  .preface {
    display: inline;
    font-weight: 600;
    text-transform: none;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

button.button {
  font-size: 110%;
  padding: 0.75rem;
  line-height: 1;
  background: #e1e1e1;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none !important;
  cursor: pointer;

  &.full {
    width: 100%;
  }

  &.clear {
    background: rgba(0, 0, 0, 0);

    &:hover {
      background: rgba(251, 181, 22, 0.25);
    }
  }

  &.yellow {
    color: #e6e7e8;
    font-family: 'Raleway';
    background: #007ec0; // "Ocean" from our colors js file. Need to right components for this
  }
}

#root {
  height: 100%;
}

.as-link {
  cursor: pointer;
  text-decoration: none;
  color: #0059c9;
}

.halfsies {
  flex: 1;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  max-height: 125px;
  min-height: 125px;

  div {
    padding: 1rem;
  }

  .btn-action {
    span {
      font-size: 90%;
    }

    button {
      font-size: 150%;
      line-height: 1;
      letter-spacing: -1px;
      font-weight: 200;
    }

    &.returning {
      text-align: center;
    }

    &.setup {
      text-align: center;
    }
  }
}
