#view-id-card {
  .view-footer {
    padding: 1.5rem 0;
  }
  .view-content {
    background: rgba(255, 255, 255, 0.75);
    padding: 4rem 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 50px;
  }
  .help-text {
    margin-bottom: 25px;
  }
}
