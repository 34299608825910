section#view-support {
  div.view-content {
    padding: 4rem 2rem;
    text-align: center;
  }

  .section-header {
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    font-weight: 500;
  }
}
