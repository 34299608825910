@import '../../styles/common';

section#view-enter-code {
  div.view-content {
    padding: 4rem 2rem;
    text-align: center;

    .main-content {
      max-width: 450px;
    }

    .enter-code-form {
      width: 250px;
    }

    .enter-code-container {
      padding-top: 25px;
      display: flex;
      justify-content: center;
    }
  }
}
