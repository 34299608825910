@import '../../styles/common';

div#signup {
  max-width: 450px;
  .fieldset-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media screen and (min-width: $mobileBreakpoint) {
    .btn-footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      button.button {
        margin-bottom: 10px;
      }
    }
  }
}
