.logo-loader {
  position: relative;
  top: 0;
  z-index: 10;
  transition: all 0.25s ease;
  opacity: 1;
  height: 75px;
  width: 75px;

  .progresser {
    transition: all 0.25s ease;

    &.spinner {
      transform-origin: center center;
      animation: logo-loading 1s infinite forwards ease-in-out;
    }
  }
}

@keyframes logo-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes logo-textstroked-in {
  50% {
    opacity: 1;
  }
  90% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logo-textfilled-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
