@import '../../styles/common';

section#view-login {
  div.view-content {
    padding: 4rem 2rem;

    .login-form {
      max-width: 450px;
    }
  }

  @media screen and (min-width: $mobileBreakpoint) {
    div.view-content {
      text-align: center;
    }
    .login-container {
      padding-top: 25px;
      display: flex;
      justify-content: center;

      .login-form {
        max-width: 450px;
      }
    }
  }
}
