@import '../../styles/common';

section#view-home {
  .view-content {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 50px;
    padding: 0 1rem;
    background: rgba(255, 255, 255, 0.75);

    .top-content {
      margin-top: 50px;
    }

    .mobile-id-card {
      margin-bottom: 1.5rem;
    }

    .member-card-minimal {
      position: relative;
      text-align: left;
      background: #fff;
      border-radius: 12px;
      background: radial-gradient(circle at 40% 54%, #fff 30%, #ddd);
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.3);
      margin-bottom: 1.5rem;
      cursor: pointer;

      &.inactive {
        color: #d3d3d3;
      }

      &::after {
        content: '';
        min-width: 100%;
        height: 20px;
        background: #aaa;
        position: absolute;
        top: 100%;
        left: -20px;
        right: -20px;
        filter: blur(7px);
        z-index: -1;
        margin-top: -9px;
        border-radius: 30%;
        opacity: 0.7;
      }

      h4 {
        position: relative;
        padding: 1rem 0 1rem 5rem;
        margin: 0;

        i {
          font-size: 200%;
          position: absolute;
          top: 50%;
          left: 2rem;
          transform: translateY(-50%);
        }
      }
    }
  }

  @media screen and (min-width: $mobileBreakpoint) {
    margin-top: 50px;
    .view-content {
      overflow-y: overlay;
      max-height: unset;
      width: 100%;
      border-radius: 0px;

      .id-card-container {
        max-width: unset;
        display: flex;
        justify-content: center;
      }

      .mobile-id-card {
        min-height: 300px;
        max-height: 325px;
        width: 400px;
      }
    }

    section.home-list {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }
  }

  .home-list {
    .options-row {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
    }

    .option-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      min-height: 150px;
      min-width: 75px;
      font-weight: 300;
      font-size: 150%;
      padding: 1rem;

      i {
        padding: 10px;
        font-size: 175%;
      }
      .option-text {
        text-align: center;
      }
    }
  }
}
