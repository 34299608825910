header.app-header {
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .header-icon {
    font-size: 200%;
    font-weight: 900;
    color: white;
  }

  .logo-loader {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .identifier,
  .logout {
    display: inline-block;
    font-size: 90%;
    padding: 0.4rem 0;
  }

  .logout {
    cursor: pointer;

    span {
      display: inline-block;
      padding: 0.2rem;
      border-bottom: 1px solid #bbb;
    }
  }
}

.section-header {
  font-size: 125%;

  &.fieldset {
    margin-bottom: 25px !important;
  }
}
