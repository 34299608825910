div.successful-container {
  max-width: 550px;

  .code-container {
    width: 250px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}
