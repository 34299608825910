$icomoon-font-path: '/assets/fonts' !default;

$icon-arrow-right: '\e906';
$icon-arrow-left: '\e907';
$icon-arrow-down: '\e908';
$icon-arrow-up: '\e909';
$icon-close: '\e900';
$icon-check: '\e901';
$icon-uni6B: '\6b';
$icon-circle-twitter: '\63';
$icon-circle-google-plus: '\64';
$icon-circle-pinterest: '\65';
$icon-circle-instagram: '\66';
$icon-uni6C: '\6c';
$icon-circle-facebook: '\67';
$icon-uni6D: '\6d';
$icon-circle-youtube: '\68';
$icon-uni6E: '\6e';
$icon-circle-mail: '\6a';
$icon-uni6F: '\6f';
$icon-circle-rss: '\69';
$icon-server: '\f233';
$icon-tasks: '\f0ae';
$icon-bars: '\f0c9';
$icon-navicon: '\f0c9';
$icon-reorder: '\f0c9';
$icon-circle: '\f111';
$icon-angle-up: '\e628';
$icon-angle-right: '\e62c';
$icon-angle-left: '\e630';
$icon-angle-down: '\e634';
$icon-ticket: '\e645';
$icon-search: '\e651';
$icon-refresh: '\e657';
$icon-paperclip: '\e65a';
$icon-paper-plane: '\e671';
$icon-menu: '\e680';
$icon-lintern: '\e68a';
$icon-link: '\e68b';
$icon-less: '\e68e';
$icon-keypad: '\e68f';
$icon-info: '\e691';
$icon-gleam: '\e69a';
$icon-expand1: '\e6a1';
$icon-download: '\e6a6';
$icon-config: '\e6b0';
$icon-chat: '\e6b7';
$icon-box2: '\e6bd';
$icon-battery: '\e6c2';
$icon-attention: '\e6c5';
$icon-more: '\e67c';
$icon-look: '\e688';
$icon-graph1: '\e697';
$icon-edit: '\e6a3';
$icon-drawer: '\e6a5';
$icon-camera: '\e6b9';
$icon-album: '\e600';
$icon-arc: '\e601';
$icon-back-2: '\e602';
$icon-bandaid: '\e603';
$icon-car: '\e604';
$icon-diamond: '\e605';
$icon-door-lock: '\e606';
$icon-eyedropper: '\e607';
$icon-female: '\e608';
$icon-gym: '\e609';
$icon-hammer: '\e60a';
$icon-headphones: '\e60b';
$icon-helm: '\e60c';
$icon-hourglass: '\e60d';
$icon-leaf: '\e60e';
$icon-magic-wand: '\e60f';
$icon-male: '\e610';
$icon-map-22: '\e612';
$icon-next-2: '\e613';
$icon-paint-bucket: '\e614';
$icon-pendrive: '\e615';
$icon-photo: '\e616';
$icon-piggy: '\e617';
$icon-plugin: '\e618';
$icon-refresh-2: '\e619';
$icon-rocket: '\e61a';
$icon-settings: '\e61b';
$icon-shield: '\e61c';
$icon-smile: '\e61d';
$icon-usb: '\e61e';
$icon-vector: '\e61f';
$icon-wine: '\e620';
$icon-cloud-upload: '\e621';
$icon-cash: '\e623';
$icon-bluetooth: '\e625';
$icon-cloud-download: '\e627';
$icon-way: '\e629';
$icon-id: '\e62d';
$icon-wristwatch: '\e631';
$icon-world: '\e635';
$icon-volume: '\e638';
$icon-users: '\e63a';
$icon-user-female: '\e63c';
$icon-switch: '\e640';
$icon-scissors: '\e642';
$icon-wallet: '\e644';
$icon-safe: '\e646';
$icon-volume2: '\e647';
$icon-volume1: '\e648';
$icon-voicemail: '\e649';
$icon-video: '\e64a';
$icon-user: '\e64b';
$icon-upload: '\e64d';
$icon-unlock: '\e64e';
$icon-umbrella: '\e64f';
$icon-trash: '\e650';
$icon-tools2: '\e652';
$icon-timer: '\e653';
$icon-ticket2: '\e654';
$icon-target: '\e655';
$icon-sun: '\e656';
$icon-study: '\e658';
$icon-stopwatch: '\e659';
$icon-star: '\e65b';
$icon-speaker: '\e65c';
$icon-signal2: '\e65d';
$icon-shuffle: '\e65e';
$icon-shopbag: '\e65f';
$icon-share: '\e660';
$icon-server2: '\e662';
$icon-search2: '\e663';
$icon-film: '\e664';
$icon-science: '\e666';
$icon-disk: '\e667';
$icon-ribbon: '\e668';
$icon-repeat: '\e669';
$icon-refresh2: '\e66a';
$icon-add-user: '\e66b';
$icon-refresh-cloud: '\e66c';
$icon-paperclip2: '\e66d';
$icon-radio: '\e66e';
$icon-note: '\e66f';
$icon-print: '\e670';
$icon-network: '\e672';
$icon-prev: '\e673';
$icon-mute: '\e674';
$icon-power2: '\e675';
$icon-medal: '\e676';
$icon-portfolio: '\e677';
$icon-like: '\e678';
$icon-play: '\e67b';
$icon-key: '\e67d';
$icon-plane: '\e67e';
$icon-joy: '\e67f';
$icon-photo-gallery: '\e684';
$icon-pin: '\e685';
$icon-phone: '\e686';
$icon-plug: '\e687';
$icon-pen: '\e689';
$icon-delete-user: '\e690';
$icon-paint: '\e692';
$icon-notebook: '\e694';
$icon-note2: '\e695';
$icon-next: '\e696';
$icon-news-paper: '\e698';
$icon-musiclist: '\e699';
$icon-music: '\e69b';
$icon-mouse: '\e69c';
$icon-more2: '\e69d';
$icon-moon: '\e69e';
$icon-monitor: '\e69f';
$icon-micro: '\e6a0';
$icon-menu2: '\e6a2';
$icon-map2: '\e6a4';
$icon-map-marker2: '\e6a7';
$icon-mail22: '\e6a8';
$icon-mail-open: '\e6a9';
$icon-mail-open-file: '\e6aa';
$icon-magnet: '\e6ab';
$icon-loop: '\e6ac';
$icon-look2: '\e6ad';
$icon-lock: '\e6ae';
$icon-lintern2: '\e6af';
$icon-link2: '\e6b1';
$icon-like2: '\e6b2';
$icon-light: '\e6b3';
$icon-less2: '\e6b4';
$icon-junk: '\e6b8';
$icon-info2: '\e6ba';
$icon-home: '\e6bb';
$icon-help2: '\e6bc';
$icon-help1: '\e6be';
$icon-graph3: '\e6bf';
$icon-graph2: '\e6c0';
$icon-graph: '\e6c3';
$icon-global: '\e6c4';
$icon-gleam2: '\e6c6';
$icon-glasses: '\e6c7';
$icon-gift: '\e6c8';
$icon-folder: '\e6c9';
$icon-flag: '\e6ca';
$icon-filter: '\e6cb';
$icon-file: '\e6cc';
$icon-expand12: '\e6cd';
$icon-exapnd2: '\e6ce';
$icon-edit2: '\e6cf';
$icon-drop: '\e6d0';
$icon-drawer2: '\e6d1';
$icon-download2: '\e6d2';
$icon-display2: '\e6d3';
$icon-display1: '\e6d4';
$icon-diskette: '\e6d5';
$icon-date: '\e6d6';
$icon-cup: '\e6d7';
$icon-culture: '\e6d8';
$icon-crop: '\e6d9';
$icon-credit: '\e6da';
$icon-copy-file: '\e6db';
$icon-compass: '\e6dd';
$icon-comment: '\e6de';
$icon-coffee: '\e6df';
$icon-cloud: '\e6e0';
$icon-clock: '\e6e1';
$icon-chat2: '\e6e3';
$icon-cart: '\e6e4';
$icon-camera2: '\e6e5';
$icon-call: '\e6e6';
$icon-calculator: '\e6e7';
$icon-browser: '\e6e8';
$icon-box1: '\e6ea';
$icon-bookmarks: '\e6eb';
$icon-bicycle: '\e6ec';
$icon-bell: '\e6ed';
$icon-battery2: '\e6ee';
$icon-ball: '\e6ef';
$icon-back: '\e6f0';
$icon-attention2: '\e6f1';
$icon-anchor: '\e6f2';
$icon-albums: '\e6f3';
$icon-alarm: '\e6f4';
$icon-airplay: '\e6f5';
$icon-instagram: '\e903';
$icon-social: '\e903';
$icon-instagram2: '\e904';
$icon-brand: '\e904';
$icon-social2: '\e904';
$icon-paypal: '\e905';
$icon-brand2: '\e905';
$icon-donate: '\e905';
$icon-plus: '\e902';
