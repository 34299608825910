.signup-text-block {
  margin-bottom: 14px;
}

.first-field {
  padding-top: 10px;
}

.verify {
  margin-bottom: 75px;
}

.btn-footer {
  text-align: center;

  button.button {
    min-width: 40%;
  }
}
