@import '../../styles/common';

.walkthrough {
  .content-step {
    width: 100%;
    height: 100%;

    .step-content {
      display: inline-block;

      p {
        line-height: 1.6;

        .preface {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (min-width: $mobileBreakpoint) {
  .walkthrough {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .intro {
      margin-bottom: 0px;
    }

    .footer-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 35px;

      button.goto {
        postition: static;
        width: 50%;
        padding: 15px;
        i.next-arrow {
          display: none;
        }
        i.back-arrow {
          display: none;
        }
      }
    }

    .welcome-step {
      span {
        &.sm {
          font-size: 16px;
          font-weight: 100;
          text-align: center;
        }
        &.lg {
          font-size: 36px;
          font-weight: 200;
          text-align: center;
        }
      }

      .welcome-content {
        margin-top: 25px;
      }
    }
    .mobile-stepper {
      display: none;
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .walkthrough {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .footer-buttons {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button.goto {
        postition: static;
        width: 50%;
        padding: 15px;
        i.next-arrow {
          display: none;
        }
        i.back-arrow {
          display: none;
        }
      }
    }

    .welcome-step {
      span {
        &.sm {
          font-size: 16px;
          font-weight: 100;
          text-align: center;
        }
        &.lg {
          font-size: 36px;
          font-weight: 200;
          text-align: center;
        }
      }

      .welcome-content {
        margin-top: 25px;
      }
    }
    .stepper {
      display: none;
    }
  }
}
