@import '../../styles/common';

section.view {
  transition: all 0.5s ease;
  width: 100%;
  font-family: 'Raleway';
  height: 100%;
  display: flex;
  flex-direction: column;

  // webkit (safari/chrome) scrollbar styling
  .view-content::-webkit-scrollbar {
    width: 0 !important;
  }
  .view-content {
    padding: 4rem 2rem;

    -ms-overflow-style: none; //ie scrollbar styling
    scrollbar-width: none; //firefox scrollbar styling
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 4;
    width: 100%;
    transition: all 0.35s ease-in-out;
    &:first-child {
      margin-top: 50px;
    }

    h3 {
      font-size: 250%;
      font-weight: 300;
      margin: 0;
      padding-bottom: 1rem;
    }
  }

  @media screen and (min-width: $mobileBreakpoint) {
    .view-content {
      text-align: center;
      min-width: 500px;
      margin: 0 auto;
    }
    &.fluid {
      .view-content {
        max-height: inherit;
        height: auto;
      }
    }
  }
}
