@import '../../styles/common';

#walkthrough,
#view-home {
  .view-content {
    &.intro {
      margin-bottom: 0px;
    }

    .walkthrough {
      max-height: 500px;
    }
  }

  .tutorial {
    z-index: 1;
  }

  .halfsies {
    top: 100%;
    width: 100%;
  }

  .footer {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-action {
    display: inline-block;
    width: 50%;
    text-transform: uppercase;
    position: relative;
    transition: all 0.15s ease-in-out;

    span {
      display: block;
      text-decoration: underline;
      color: #666;

      &.returning {
        text-align: center;
      }

      &.new-here {
        text-align: center;
      }
    }

    button {
      background: none;
      outline: none;
      border: 0;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
  }

  @media screen and (min-width: $mobileBreakpoint) {
    .view-content {
      text-align: center;
      overflow-y: scroll;
      margin: 0 auto;

      .step-content,
      .welcome-content {
        max-width: 750px;
      }
    }

    .btn-action {
      padding: 1.5rem 2rem 2.5rem;

      span {
        font-size: 90%;
        &.returning {
          text-align: right;
        }

        &.new-here {
          text-align: left;
        }
      }

      button {
        font-size: 260%;
        line-height: 1;
        letter-spacing: -1px;
        font-weight: 200;
      }
    }
  }

  &.leave {
    &::before {
      left: 100%;
    }
    &::after {
      right: 100%;
    }
    .logo-loader {
      opacity: 0;
    }
    .view-content {
      transform: scale(1.3, 1.3);
      opacity: 0;
      transform-origin: 50% 50%;
    }
    .halfsies {
      top: 130%;
      opacity: 0;
    }
  }
}
