section#view-profile {
  div.profile-container {
    text-align: left;
  }

  .section-header {
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    font-weight: 500;

    &.text-center {
      text-align: center;
    }

    &.top-header {
      margin-top: 0;
    }
  }

  .note-text {
    margin-top: 1.25rem;
  }

  .field-label {
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    font-weight: 300;
  }

  .logout-link {
    color: #27afc7;
  }

  .action-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

section.view {
  div.view-content.profile {
    overflow-x: inherit;
    overflow-y: inherit;
  }
}
